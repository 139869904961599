var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-wrap step-1"},[_c('div',{staticClass:"register-overlay"}),_vm._v(" "),_c('div',{staticClass:"carousel slide register-slider",attrs:{"id":"carouselExampleIndicators","data-ride":"carousel"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"carousel-inner register-carousel"},[_c('div',{staticClass:"carousel-item active"},[_c('div',{staticClass:"register-slider-caption"},[_c('h2',[_vm._v(_vm._s(_vm.initialData.lang.app_manage_pets))])]),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"register-slider-caption"},[_c('h2',[_vm._v(_vm._s(_vm.initialData.lang.app_renew_pet_licenses))])]),_vm._v(" "),_vm._m(2)]),_vm._v(" "),_c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"register-slider-caption"},[_c('h2',[_vm._v(_vm._s(_vm.initialData.lang.app_manage_pets))])]),_vm._v(" "),_vm._m(3)]),_vm._v(" "),_c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"register-slider-caption"},[_c('h2',[_vm._v(_vm._s(_vm.initialData.lang.app_renew_pet_licenses))])]),_vm._v(" "),_vm._m(4)]),_vm._v(" "),_c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"register-slider-caption"},[_c('h2',[_vm._v(_vm._s(_vm.initialData.lang.app_manage_pets))])]),_vm._v(" "),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"carousel-indicators"},[_c('li',{staticClass:"active",attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"0"}}),_vm._v(" "),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"1"}}),_vm._v(" "),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"2"}}),_vm._v(" "),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"3"}}),_vm._v(" "),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"4"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-slider-icon bg-white"},[_c('span',{staticClass:"icon-pawprints"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-slider-icon bg-white"},[_c('span',{staticClass:"icon-flag"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-slider-icon bg-white"},[_c('span',{staticClass:"icon-pawprints"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-slider-icon bg-white"},[_c('span',{staticClass:"icon-flag"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-slider-icon bg-white"},[_c('span',{staticClass:"icon-pawprints"})])
}]

export { render, staticRenderFns }